var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-300px pl-4 pb-4"},[_c('v-combobox',{attrs:{"items":_vm.countries,"label":_vm.$t('commonTable.country'),"item-text":"name","item-value":"id","outline":""},on:{"change":_vm.onChangeCountry},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shops,"options":_vm.pagination,"server-items-length":_vm.totalShops,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('v-select',{staticClass:"type",attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","single-line":"","return-object":"","disabled":_vm.loading},on:{"change":function($event){return _vm.changeCategory(item.id, item.tierId)}},model:{value:(item.tierId),callback:function ($$v) {_vm.$set(item, "tierId", $$v)},expression:"item.tierId"}})],1)]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.createDate))+" ")]}},{key:"item.country",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopLocations != null && item.shopLocations.length > 0 && item.shopLocations[0].city != null ? item.shopLocations[0].city.name : "")+" "+_vm._s(item.country != null ? "(" + item.country.name + ")" : "")+" ")])]}},{key:"item.activated",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.activateShop(item.id, item.activated)}},model:{value:(item.activated),callback:function ($$v) {_vm.$set(item, "activated", $$v)},expression:"item.activated"}})]}},{key:"item.groupShopping",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changeGroupShopping(item.id, item.groupShopping)}},model:{value:(item.groupShopping),callback:function ($$v) {_vm.$set(item, "groupShopping", $$v)},expression:"item.groupShopping"}})]}},{key:"item.shoppingEvent",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changeShoppingEvent(item.id, item.shoppingEvent)}},model:{value:(item.shoppingEvent),callback:function ($$v) {_vm.$set(item, "shoppingEvent", $$v)},expression:"item.shoppingEvent"}})]}},{key:"item.cityShopping",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
            name: 'ShopCityShopping',
            params: { shopId: item.id, shopName: item.name }
          }}},[(item.shoppingCities.length > 0)?_c('v-avatar',{attrs:{"color":"amber darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-city")])],1):_vm._e(),(item.shoppingCities.length < 1)?_c('v-avatar',{attrs:{"color":"amber accent-5","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-city")])],1):_vm._e()],1)],1)]}},{key:"item.suggestedShopping",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changeSuggestedShopping(item.id, item.suggestedShopping)}},model:{value:(item.suggestedShopping),callback:function ($$v) {_vm.$set(item, "suggestedShopping", $$v)},expression:"item.suggestedShopping"}})]}},{key:"item.redirectUrl",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changeRedirectUrl(item.id, item.redirectUrl)}},model:{value:(item.redirectUrl),callback:function ($$v) {_vm.$set(item, "redirectUrl", $$v)},expression:"item.redirectUrl"}})]}},{key:"item.donations",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changeDonations(item.id, item.donations)}},model:{value:(item.donations),callback:function ($$v) {_vm.$set(item, "donations", $$v)},expression:"item.donations"}})]}},{key:"item.personalSpecialOffer",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changePersonalSpecialOffer(item.id, item.personalSpecialOffer)}},model:{value:(item.personalSpecialOffer),callback:function ($$v) {_vm.$set(item, "personalSpecialOffer", $$v)},expression:"item.personalSpecialOffer"}})]}},{key:"item.banned",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50","disabled":_vm.loading},on:{"click":function($event){return _vm.changeBanStatus(item.id, item.banned)}},model:{value:(item.banned),callback:function ($$v) {_vm.$set(item, "banned", $$v)},expression:"item.banned"}})]}},{key:"item.subscriptions",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'ShopSubscriptions',
          params: { shopId: item.id, shopName: item.name }
        }}},[_c('v-avatar',{attrs:{"color":"green accent-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("flaticon-piggy-bank")])],1)],1)]}},{key:"item.promotionSubscription",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'ShopPromotionSubscription',
          params: { shopId: item.id, shopName: item.name }
        }}},[_c('v-avatar',{attrs:{"color":"blue","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-calendar-check")])],1)],1)]}},{key:"item.walletGift",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
            name: 'ShopWalletGift',
            params: { shopId: item.id, shopName: item.name }
          }}},[_c('v-avatar',{attrs:{"color":"orange","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-gift")])],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('router-link',{attrs:{"to":{
              name: 'ShopTags',
              params: { shopId: item.id }
            }}},[_c('v-list-item',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"green","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-tag")])],1),_vm._v(" Shop tags ")],1)],1),_c('router-link',{attrs:{"to":{
              name: 'ShopEdit',
              params: { shopId: item.id, shopName: item.name }
            }}},[_c('v-list-item',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),_vm._v(" Edit shop ")],1)],1),_c('v-list-item',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"indigo","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.goToShopDialog(item.id)}}},[_vm._v("mdi-shopping")])],1),_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v("Shop profile")])],1),_c('v-list-item',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"green","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-tag")])],1),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.sendQRCode(item.id)}}},[_vm._v("Send QR code activation")])],1)],1)],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.shopId),callback:function ($$v) {_vm.shopId=$$v},expression:"shopId"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.shopEmail),callback:function ($$v) {_vm.shopEmail=$$v},expression:"shopEmail"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.shopPhoneNumber),callback:function ($$v) {_vm.shopPhoneNumber=$$v},expression:"shopPhoneNumber"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.categories,"item-text":"name","item-value":"id"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('td',[_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.from'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.to'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.userCities,"loading":_vm.citiesIsLoading,"search-input":_vm.search,"clearable":"","item-text":"name","item-value":"id","label":"City"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("cities")])])],1)]},proxy:true},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}]),model:{value:(_vm.shopCity),callback:function ($$v) {_vm.shopCity=$$v},expression:"shopCity"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-select',{attrs:{"items":_vm.shopActivatedList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.shopActivated),callback:function ($$v) {_vm.shopActivated=$$v},expression:"shopActivated"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.groupShoppingList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.groupShopping),callback:function ($$v) {_vm.groupShopping=$$v},expression:"groupShopping"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.shoppingEventList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.shoppingEventActivated),callback:function ($$v) {_vm.shoppingEventActivated=$$v},expression:"shoppingEventActivated"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.redirectUrlList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.redirectUrl),callback:function ($$v) {_vm.redirectUrl=$$v},expression:"redirectUrl"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.bannedList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.banned),callback:function ($$v) {_vm.banned=$$v},expression:"banned"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.cityShoppingList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShops();}},model:{value:(_vm.cityShopping),callback:function ($$v) {_vm.cityShopping=$$v},expression:"cityShopping"}})],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveShops();}}},[_vm._v("mdi-magnify ")])],1),_c('v-spacer',{staticClass:"pt-2 pb-2"}),_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)])]},proxy:true}])}),_c('ShopDialog',{attrs:{"shop":_vm.shop},on:{"close":function($event){_vm.showShopDialog = false}},model:{value:(_vm.showShopDialog),callback:function ($$v) {_vm.showShopDialog=$$v},expression:"showShopDialog"}}),_c('div',{staticClass:"table-footer-prepend d-flex pl-2 align-center"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.downloadingExcel,"disabled":_vm.downloadingExcel,"color":"green darken-1"},on:{"click":function($event){_vm.loader = 'downloadingExcel';
        _vm.downloadExcel();}}},[_vm._v(" "+_vm._s(_vm.$t("pages.shops.activeShopsWithPostCount"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }